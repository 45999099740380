// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

require('jquery')
require('bootstrap/dist/js/bootstrap.bundle')

require('@fortawesome/fontawesome-free/js/fontawesome')
require('@fortawesome/fontawesome-free/js/solid')
FontAwesome.config.mutateApproach = 'sync'
